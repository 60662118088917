import React, { PropsWithChildren, useEffect } from "react";
import { SearchBlock } from "./components/SearchBlock/SearchBlock";
import { SliderProps } from "./components/SearchBlock/Slider";
import { PropertySwiper } from "./components/PropertySwiper/PropertySwiper";
import { QueryClient, QueryClientProvider } from "react-query";
import { AxiosConfigContext, AxiosRequestConfig } from "@bitperfect-packages/react-query-axios";
import { FilterContextProvider, useFilterContext } from "./contexts/FilterContext";
import { useListObjects } from "./api/hooks/useObjects";
import { TailSpin } from "react-loader-spinner";

const queryClient = new QueryClient();

type PropertyHandlerProps = {
    preview: boolean;
    token: string;
    filters: Array<SliderProps>;
    offerText: string;
    detailLink: string;
    buttonText?: string;
    link?: string;
};

type ContextWrapperProps = PropsWithChildren & {
    token: string;
};

export default function PropertyHandler(props: PropertyHandlerProps) {
    return (
        <ContextWrapper token={props.token}>
            <PropertyComponent
                preview={props.preview}
                token={props.token}
                filters={props.filters}
                offerText={props.offerText}
                detailLink={props.detailLink}
                buttonText={props.buttonText}
                link={props.link}
            />
        </ContextWrapper>
    );
}

const PropertyComponent = (props: PropertyHandlerProps) => {
    const [filter, setFilter] = useFilterContext();
    const objectQuery = useListObjects({ limit: 100, usenrs: "Wohnraum" });

    useEffect(() => {
        if (objectQuery.isSuccess) {
            const properties = objectQuery.data;
            let minRent: number = 0;
            let maxRent: number = 0;
            let minArea: number = 0;
            let maxArea: number = 0;
            let minRooms: number = 0;
            let maxRooms: number = 0;

            properties.forEach((property) => {
                const rent = property.prices.rent_all;
                const area = property.space;
                const rooms = Number(property.rooms);

                if (rent < minRent || minRent === 0) {
                    minRent = rent;
                }
                if (rent > maxRent) {
                    maxRent = rent;
                }
                if (area < minArea || minArea === 0) {
                    minArea = area;
                }
                if (area > maxArea) {
                    maxArea = area;
                }
                if (rooms < minRooms || minRooms === 0) {
                    minRooms = rooms;
                }
                if (rooms > maxRooms) {
                    maxRooms = rooms;
                }
            });

            const currentFilter = new Map(filter);
            currentFilter.set("rent", getInitialFilterValue("rent", minRent, maxRent, true));
            currentFilter.set("area", getInitialFilterValue("area", minArea, maxArea));
            currentFilter.set("rooms", getInitialFilterValue("rooms", minRooms, maxRooms));
            setFilter(currentFilter);
        }
    }, [objectQuery.isSuccess]);

    const getInitialFilterValue = (name: string, min: number, max: number, useMax: boolean = false) => {
        const getValue = (useMax, min, max) => {
            if (useMax) {
                return Math.ceil(max);
            } else {
                return Math.floor(min);
            }
        };

        const currentFilter = new Map(filter);
        const tempFilter = currentFilter.get(name) ?? {
            value: props.filters.find((f) => f.name === name)?.value ?? 0,
        };

        tempFilter.min = Math.floor(min);
        tempFilter.max = Math.ceil(max);
        tempFilter.value = tempFilter.value != 0 ? tempFilter.value : getValue(min, max, useMax);

        return tempFilter;
    };

    const onButtonClick = () => {
        if (props.link) {
            let url = props.link;
            filter.forEach((value, key) => {
                if (url === props.link) {
                    url += "?";
                }
                url += `${key}=${value.value}&`;
            });
            url = url.slice(0, -1);
            window.open(`${url}`, "_self");
        } else {
            console.error("No appropriate action for button, link is not set");
        }
    };

    return objectQuery.isLoading ? (
        <div className={"block"} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <TailSpin height="60" width="60" color="#083060" ariaLabel="tail-spin-loading" radius="1" visible={true} />
        </div>
    ) : (
        <>
            <div className={"property-search-section block"}>
                <SearchBlock
                    offerText={props.offerText}
                    filters={props.filters}
                    buttonSettings={{
                        buttonVisible: !props.preview,
                        buttonText: props.buttonText,
                        buttonClick: onButtonClick,
                    }}
                />
            </div>
            {props.preview ? (
                <div className={"property-preview-section block"}>
                    <PropertySwiper detailLink={props.detailLink} />
                </div>
            ) : null}
        </>
    );
};

const ContextWrapper = (props: ContextWrapperProps) => {
    const defaultRequestConfig: AxiosRequestConfig = {
        baseURL: "https://die-luckenwalder.ivm-professional.de/interface/v1.0/",
        responseType: "json",
        headers: {
            Authorization: props.token,
            "Content-Type": "multipart/form-data",
        },
        timeout: 10000,
    };

    return (
        <QueryClientProvider client={queryClient}>
            <AxiosConfigContext.Provider value={defaultRequestConfig}>
                <FilterContextProvider>{props.children}</FilterContextProvider>
            </AxiosConfigContext.Provider>
        </QueryClientProvider>
    );
};
