import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { useFilterContext } from "../../contexts/FilterContext";
import { useListObjects } from "../../api/hooks/useObjects";
import { TailSpin } from "react-loader-spinner";
import { PropertyComponent } from "./Property";

type PropertySwiperProps = {
    detailLink: string;
};

export const PropertySwiper = (props: PropertySwiperProps) => {
    const [filter, setFilter] = useFilterContext();
    const objectQuery = useListObjects({
        limit: 100,
        space_from: filter.get("area")?.value ?? 0,
        rooms_from: filter.get("rooms")?.value ?? 0,
        rent_all_to: filter.get("rent")?.value ?? 9999,
        usenrs: "Wohnraum",
    });

    const properties = objectQuery.data ?? [];

    const contentRef = useRef(null);

    return (
        <>
            {properties && properties.length > 0 ? (
                <h2 className={"number-of-results"}>
                    {properties.length} {properties.length > 1 ? "Ergebnisse" : "Ergebnis"}
                </h2>
            ) : null}
            <div className={"content property-slider"} ref={contentRef}>
                <div className={"property-previews"}>
                    <Swiper
                        className={"property-preview-swiper"}
                        direction={"horizontal"}
                        loop={false}
                        slidesPerView={"auto"}
                        spaceBetween={24}
                        centeredSlides={true}
                        autoHeight={true}
                        navigation={true}
                        modules={[Navigation]}
                        style={{ minHeight: 100 }}
                    >
                        {!properties && objectQuery.isLoading ? (
                            <SwiperSlide className={"image-item"}>
                                <div className={"property-box"}>
                                    <div
                                        className={"info loading"}
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: "20px",
                                        }}
                                    >
                                        <h4 className={"description"} style={{ margin: 0 }}>
                                            Loading
                                        </h4>
                                        <TailSpin
                                            height="60"
                                            width="60"
                                            color="#083060"
                                            ariaLabel="tail-spin-loading"
                                            radius="1"
                                            visible={true}
                                        ></TailSpin>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ) : properties && properties.length > 0 ? (
                            properties.map((property, index) => {
                                return (
                                    <SwiperSlide key={index} className={"image-item"}>
                                        <PropertyComponent
                                            property={property}
                                            containerRef={contentRef}
                                            detailLink={props.detailLink}
                                        />
                                    </SwiperSlide>
                                );
                            })
                        ) : (
                            <SwiperSlide className={"image-item"}>
                                <div className={"property-box"}>
                                    <div className={"info"}>
                                        <h4 className={"description"} style={{ margin: 0 }}>
                                            Keine Immobilien gefunden
                                        </h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
            </div>
        </>
    );
};
