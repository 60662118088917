import { Filter } from "../types/custom";
import React, { useContext, useState } from "react";
import { useListObjects } from "../api/hooks/useObjects";

export type FilterContextType = [Map<string, Filter> | null, React.Dispatch<Map<string, Filter> | null>];

export const FilterContext = React.createContext<FilterContextType | null>(null);

export const useFilterContext = (): FilterContextType => {
    const filterContext = useContext(FilterContext);
    if (filterContext === null) {
        throw new Error("No FilterContext set!");
    }

    return filterContext;
};

export const FilterContextProvider = (props: React.PropsWithChildren<{}>) => {
    const filter = useState<Map<string, Filter>>(new Map<string, Filter>());

    return <FilterContext.Provider value={filter}>{props.children}</FilterContext.Provider>;
};
