import React, { useEffect, useState } from "react";
import { useFilterContext } from "../../contexts/FilterContext";
import { Filter } from "../../types/custom";

export type SliderProps = {
    name: string;
    label: string;
    value: number;
};

export const Slider = (props: SliderProps) => {
    const [filter, setFilter] = useFilterContext();
    const [valueRatio, setValueRatio] = useState(0);
    const [value, setValue] = useState<number>(props.value);
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(10000);

    useEffect(() => {
        const sliderFilter = filter.get(props.name) ?? null;
        if (sliderFilter) {
            setMin(sliderFilter.min ?? 0);
            setMax(sliderFilter.max ?? 0);
        }
    }, [filter.get(props.name)?.min, filter.get(props.name)?.max]);

    useEffect(() => {
        if (value < min) {
            setValue(min);
            setFilterValue(min);
        }
        if (value > max) {
            setValue(max);
            setFilterValue(max);
        }
    }, [min, max]);

    useEffect(() => {
        setValueRatio(((value - min) * 100) / (max - min));
    }, [min, max, value]);

    useEffect(() => {
        if (props.name === "rent" && Number(props.value) === 0) {
            setValue(max);
        }
    }, []);

    const setFilterValue = (newValue: number) => {
        const currentFilter = new Map(filter);
        let filterProp: Filter = currentFilter.get(props.name) ?? { value: 0 };
        filterProp.value = newValue;
        currentFilter.set(props.name, filterProp);
        setFilter(currentFilter);
    };

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(Number(event.target.value));
        setFilterValue(Number(event.target.value));
    };

    return (
        <div className={`slider ${props.name}`}>
            <label htmlFor={props.name} className={"label"}>
                {props.label}
            </label>
            <input
                className={"slider-input"}
                id={props.name}
                type={"range"}
                min={min.toString()}
                max={max.toString()}
                value={value}
                step={1}
                name={props.name}
                onChange={(e) => handleValueChange(e)}
            />
            <option className="min" value={min} label={min.toString()}></option>
            <option className="max" value={max} label={max.toString()}></option>
            <output
                htmlFor={props.name}
                className="current bubble"
                // Kind of magic numbers based on the size of the native UI thumb
                style={{ left: `calc(${valueRatio}% + (${10 - valueRatio * 0.2}px))` }}
            >
                {value}
            </output>
        </div>
    );
};
