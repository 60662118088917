import React, { Dispatch, MutableRefObject } from "react";

type ExpanderProps = {
    containerRef: MutableRefObject<any>;
    interiorState: [string, Dispatch<string>];
};

export const Expander = (props: ExpanderProps) => {
    const [interiorClasses, setInteriorClass] = props.interiorState;

    const handleClick = () => {
        const classes = interiorClasses.split(" ");
        const index = classes.indexOf("open");
        if (index === -1) {
            classes.push("open");
        } else {
            classes.splice(index, 1);
            props.containerRef.current.scrollIntoView({ behavior: "auto", block: "start" });
        }
        setInteriorClass(classes.join(" "));
    };

    return <button className="expander" style={{ backgroundColor: "transparent" }} onClick={handleClick}></button>;
};
