import React from "react";
import { Slider, SliderProps } from "./Slider";

type ButtonSettings = {
    buttonVisible: boolean;
    buttonText?: string;
    buttonClick?: () => void;
};

export type SearchBlockProps = {
    buttonSettings: ButtonSettings;
    filters: Array<SliderProps>;
    offerText: string;
};

export const SearchBlock = (props: SearchBlockProps) => {
    return (
        <div className={"search-block"}>
            <div className={"angebote"}>{props.offerText}</div>
            {props.filters.map((f) => {
                return <Slider key={f.name} name={f.name} label={f.label} value={f.value} />;
            })}
            {props.buttonSettings.buttonVisible ? (
                <button className={"button-search"} onClick={props.buttonSettings.buttonClick}>
                    {props.buttonSettings.buttonText}
                </button>
            ) : null}
        </div>
    );
};
