import ReactDOM from "react-dom/client";
import React from "react";
import PropertyHandler from "./propertyHandler";

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

const domContainers = <NodeListOf<HTMLElement>>document.querySelectorAll(".property-handler-section");

domContainers.forEach((domContainer) => {
    const root = ReactDOM.createRoot(domContainer);
    root.render(
        React.createElement(PropertyHandler, {
            token: domContainer.dataset.token,
            preview: Boolean(domContainer.dataset.preview !== "0"),
            offerText: domContainer.dataset.offerText,
            filters: JSON.parse(domContainer.dataset.filter.replace(/\s/g, "")),
            detailLink: domContainer.dataset.detailLink,
            buttonText: domContainer.dataset.buttonText ?? undefined,
            link: domContainer.dataset.link ?? undefined,
        })
    );
});
