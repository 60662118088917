import { Expander } from "./Expander";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { useSwiper } from "swiper/react";

type InteriorProps = {
    description: string;
    containerRef: MutableRefObject<any>;
};

export const Interior = (props: InteriorProps) => {
    const [interiorClasses, setInteriorClasses] = useState("interior");
    const swiper = useSwiper();

    const interiorRef = useRef(null);
    const interiorContentRef = useRef(null);

    useEffect(() => {
        if (
            interiorRef &&
            interiorContentRef &&
            interiorRef.current.clientHeight < interiorContentRef.current.clientHeight
        ) {
            const classes = interiorClasses.split(" ");
            classes.push("expand");
            setInteriorClasses(classes.join(" "));
        }
    }, [interiorContentRef, interiorRef]);

    useEffect(() => {
        swiper.updateAutoHeight(250);
    }, [interiorClasses]);

    return (
        <div className={interiorClasses} ref={interiorRef}>
            <p ref={interiorContentRef}>{props.description}</p>
            <Expander interiorState={[interiorClasses, setInteriorClasses]} containerRef={props.containerRef} />
        </div>
    );
};
