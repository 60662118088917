import React, { MutableRefObject } from "react";
import { Property } from "../../types/custom";
import { Interior } from "./Interior";

type PropertyComponentProps = {
    property: Property;
    containerRef: MutableRefObject<any>;
    detailLink: string;
};
export const PropertyComponent = (props: PropertyComponentProps) => {
    const property = props.property;

    return (
        <div className={"property-box"}>
            {property.image ? (
                <img src={property.image.url} alt={property.image.name} className={"property-image"} />
            ) : (
                <img src="https://dummyimage.com/474x184/ccc/fff.png" alt="" className={"property-image"} />
            )}
            <div className={"info"}>
                <h4 className={"description"} style={{ margin: 0 }}>
                    {property.name ?? property.streetname}
                </h4>
                <div className={"facts"}>
                    <div className={"left"}>
                        <span className={"fact"}>
                            <strong>Zimmer</strong> {Math.floor(property.rooms)}
                        </span>
                        <br />
                        <span className={"fact"}>
                            <strong>Fläche</strong> {property.space} m²
                        </span>
                    </div>
                    <div className={"right"}>
                        <span className={"fact"}>
                            <strong>Gebiet</strong> {property.district}
                        </span>{" "}
                        <br />
                        <span className={"fact"}>
                            <strong>Geschoss</strong> {property.floor}
                        </span>
                    </div>
                    <Interior description={property.objectdescription} containerRef={props.containerRef} />
                </div>

                <div className="rent-and-link">
                    <div className="rent">
                        <h3>{property.prices.rent_all}€</h3>
                        Warmmiete inkl. NK
                    </div>
                    <a
                        className="property-link"
                        href={props.detailLink + "?id=" + property.id}
                        aria-label="Details zu diesem Objekt"
                    ></a>
                </div>
            </div>
        </div>
    );
};
