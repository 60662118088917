import { useQuery } from "react-query";
import { Property, PropertySearchParams, PropertySearchResult } from "../../types/custom";
import { AxiosRequestConfig, useMutate } from "@bitperfect-packages/react-query-axios";

const searchUrl = "objects/getSearch.json";

export const useListObjects = (data: PropertySearchParams) => {
    data.page = data.page ?? 1;

    const responseTransform = (data): PropertySearchResult => ({
        total: data.result.data.total,
        limit: data.result.data.limit,
        pages: data.result.data.pages,
        page: data.result.data.page,
        objects: data.result.data.objects,
    });

    const search = useMutate<PropertySearchResult, PropertySearchParams>("POST", searchUrl, {
        responseTransform,
    });

    const fetch = async (): Promise<Array<Property>> => {
        const properties: Array<Property> = [];
        let nextToken = false;
        do {
            const response = await search(data);
            if (response.page < response.pages) {
                data.page += 1;
                nextToken = true;
            } else {
                nextToken = false;
            }
            for (const [key, value] of Object.entries(response.objects)) {
                properties.push(value);
            }
        } while (nextToken);

        return properties;
    };

    return useQuery([searchUrl, Object.entries(data).join("-")], fetch, {
        cacheTime: 3600,
        staleTime: 3600,
        keepPreviousData: true,
    });
};
